import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ContactLauriEt from "../../components/ContactLauriEt";

import LayoutEt from "../../components/LayoutEt"
import HeaderEt from '../../components/HeaderEt'
import Seo from "../../components/Seo"

const Portfolio = () => {
  return (
    <LayoutEt>
      <Seo title="Portfoolio" description="Puidust elementmajade tootja" lang="et" />
      <HeaderEt />

      <div className="container-base">
        <div className="section-1-2-center">
          <h1 className="title-xl wel-mb-s">Portfoolio</h1>
          <p className="body-l">Ehitustööstus on muutumas ja tehaseline ehitus on käimasoleva 
innovatsioonilaine üks peamisi suundasid. Vaata, kuidas oleme aidanud 
oma koostööpartneritel paremate tulemusteni jõuda. </p></div>
      </div>

      <div className="bg-welement-pink">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Fassaadielemendid</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">


            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/paju.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="16-korruseline büroohoone Tartu südalinnas"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Paju 2 büroohoone</p>
              <p className="body wel-mb-xs">16-korruseline büroohoone Tartu südalinnas</p>
              <p className="body-s">Tartu, Eesti</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/hugo.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="6-korruseline hostel Tartu südalinnas"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Hugo.Stay hostel</p>
              <p className="body wel-mb-xs">6-korruseline hostel Tartu südalinnas</p>
              <p className="body-s">Tartu, Eesti</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/knivsta.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Kortermaja fassaadielemendid"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Knivsta kortermaja</p>
              <p className="body wel-mb-xs">Kortermaja fassaadielemendid</p>
              <p className="body-s">Knivsta, Rootsi</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/siili.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Kortermaja fassaadielemendid"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Siili kortermaja</p>
              <p className="body wel-mb-xs">Kortermaja fassaadielemendid</p>
              <p className="body-s">Tartu, Eesti</p>
            </div>


          </div>
        </div>

      </div>

      <div className="bg-welement-white">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Eramajad</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/drobak.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Neli unikaalset eramaja Oslo külje all, Drobaki väikelinnas"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Drobaki Majad</p>
              <p className="body wel-mb-xs">Neli unikaalset eramaja Oslo külje all, Drobaki väikelinnas</p>
              <p className="body-s">Drobak, Norra</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/hiiekula.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Kuus eramaja Tartu külje all. Welement disainis, projekteeris ja tootis majad"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Hiieküla maja</p>
              <p className="body wel-mb-xs">Kuus eramaja Tartu külje all. Welement disainis, projekteeris ja tootis majad</p>
              <p className="body-s">Hiieküla, Tartu</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/tartu1.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Disain, projekteerimine, tootmine, paigaldus"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Eramaja Tartus</p>
              <p className="body wel-mb-xs">Disain, projekteerimine, tootmine, paigaldus</p>
              <p className="body-s">Tartu, Eesti</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/kloogaranna.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Disain, projekteerimine, tootmine, paigaldus"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Kloogaranna maja</p>
              <p className="body wel-mb-xs">Disain, projekteerimine, tootmine, paigaldus</p>
              <p className="body-s">Kloogaranna, Eesti</p>
            </div>


          </div>
        </div>

      </div>

      <div className="bg-welement-pink">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Ridaelamud ja kortermajad</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/oa.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Kaks ridaelamut ja üks kahekorruseline korterelamu. Welementi 
                meeskond projekteeris, tootis ja paigaldas moodulitest ridaelamud ja 
                elementlahendusena korterelamu."
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Oa tänava arendus</p>
              <p className="body wel-mb-xs">Kaks ridaelamut ja üks kahekorruseline korterelamu. Welementi 
meeskond projekteeris, tootis ja paigaldas moodulitest ridaelamud ja 
elementlahendusena korterelamu. </p>
              <p className="body-s">Tartu, Eesti</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/island.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Reikyaviki äärelinnas paiknevad ühekorruselised ridaelamud. Welementi meeskond projekteeris ja tootis tehases valmistatud elemendid."
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Ridaelamud Islandil</p>
              <p className="body wel-mb-xs">Reikyaviki äärelinnas paiknevad ühekorruselised ridaelamud. Welementi meeskond projekteeris ja tootis tehases valmistatud elemendid. </p>
              <p className="body-s">Reikyavik, Island</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/helsinki.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Projekteerimine, tootmine, paigaldus"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Ridaelamud Helsingis</p>
              <p className="body wel-mb-xs">Projekteerimine, tootmine, paigaldus</p>
              <p className="body-s">Helsinki, Finland</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/holmenkollen.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Neli paarismaja Oslo kõige prestiižsemas Holmenkolleni piirkonnas"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Holmenkolleni paarismajad</p>
              <p className="body wel-mb-xs">Neli paarismaja Oslo kõige prestiižsemas Holmenkolleni piirkonnas</p>
              <p className="body-s">Oslo, Norra</p>
            </div>


          </div>
        </div>

      </div>

      <div className="bg-welement-white">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Ühiskondlikud hooned</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8 flex md:w-3/4 mx-auto">

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/lillkyrka.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Kahekorruselised hooldekodud Rootsis"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Lillkyrka LSS Hooldekodu</p>
              <p className="body wel-mb-xs">Kahekorruselised hooldekodud Rootsis</p>
              <p className="body-s">Lillkyrka, Rootsi</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/nupsik.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Ühekorruseline lasteaed Tartu äärelinnas"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Nupsiku Lasteaed</p>
              <p className="body wel-mb-xs">Ühekorruseline lasteaed Tartu äärelinnas</p>
              <p className="body-s">Tartu, Eesti</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/aland.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Welementi meeskond projekteeris, tootis ja paigaldas tehases valmistatud elemendid"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Hotell Ahvenamaal</p>
              <p className="body wel-mb-xs">Welementi meeskond projekteeris, tootis ja paigaldas tehases valmistatud elemendid</p>
              <p className="body-s">Ahvenamaa</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/nyby.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Kahekorruselised hooldekodud Rootsis"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Nyby LSS Hooldekodu</p>
              <p className="body wel-mb-xs">Kahekorruselised hooldekodud Rootsis</p>
              <p className="body-s">Nyby, Rootsi</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/orebro.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Kahekorruselised hooldekodud Rootsis"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Örebro LSS Hooldekodu</p>
              <p className="body wel-mb-xs">Kahekorruselised hooldekodud Rootsis</p>
              <p className="body-s">Örebro, Rootsi</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/almunge.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Kahekorruselised hooldekodud Rootsis"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Almunge LSS Hooldekodu</p>
              <p className="body wel-mb-xs">Kahekorruselised hooldekodud Rootsis</p>
              <p className="body-s">Almunge, Rootsi</p>
            </div>


          </div>
        </div>

      </div>

      <div className="bg-welement-pink">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Tootearendus</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8 md:w-3/4 mx-auto">

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/boklok.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Abihooned erinevatel objektidel Rootsis"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Bo Klok abihoone</p>
              <p className="body wel-mb-xs">Abihooned erinevatel objektidel Rootsis</p>
              <p className="body-s">Erinevad objektid Rootsis</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/taju.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Personaalne multimeediaruum"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">taju.space</p>
              <p className="body wel-mb-xs">Personaalne multimeediaruum</p>
              <p className="body-s">Erinevatele klientidele Skandinaavias ja Baltikumis</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/3by3.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Modulaarne ühepereeleamu"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">3by3 House</p>
              <p className="body wel-mb-xs">Modulaarne ühepereeleamu</p>
              <p className="body-s">Erinevatele klientidele Skandinaavias ja Baltikumis</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/riffen.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Welement disainis, projekteeris ja tootis restorani Tartu südalinnas"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Restoran RIFFEN</p>
              <p className="body wel-mb-xs">Welement disainis, projekteeris ja tootis restorani Tartu südalinnas</p>
              <p className="body-s">Tartu, Eesti</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../../images/portfolio/valgussaun.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Valgusinstallatsioon koostöös kunstnik Timo Tootsiga"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Valgussaun</p>
              <p className="body wel-mb-xs">Valgusinstallatsioon koostöös kunstnik Timo Tootsiga</p>
              <p className="body-s">Maajaam, Eesti</p>
            </div>


          </div>
        </div>

      </div>


      <ContactLauriEt />


    </LayoutEt>
  )

};

export default Portfolio;

